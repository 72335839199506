import { clearAuth0Tokens } from '../ApiFetch'
import { Authentication } from './Authentication'

const REFRESH_TOKENS_INTERVAL = Number(process.env.REFRESH_TOKENS_INTERVAL)
const REFRESH_SESSION_INTERVAL = Number(process.env.AUTH0_SESSION_PING_INTERVAL)

const refreshAuth0Tokens = async () => {
  clearAuth0Tokens()
  await Authentication.loadAuth0Tokens()
}

const refreshAuth0Session = () => {
  Authentication.refreshAuth0Session()
}

const createHeartbeat = (callback, interval) => {
  let lastHeartbeat = null

  return async () => {
    // allow 50ms threshhold between runs, setInterval is not always precise
    const timeSinceLastHeartbeat = Date.now() - lastHeartbeat + 50
    const shouldDoHeartbeat = timeSinceLastHeartbeat > interval && document.visibilityState === 'visible'

    if (shouldDoHeartbeat) {
      lastHeartbeat = Date.now()
      await callback()
    }
  }
}

export const Heartbeat = {
  refreshTokens: createHeartbeat(refreshAuth0Tokens, REFRESH_TOKENS_INTERVAL),
  tokensInterval: REFRESH_TOKENS_INTERVAL,
  refreshSession: createHeartbeat(refreshAuth0Session, REFRESH_SESSION_INTERVAL),
  sessionInterval: REFRESH_SESSION_INTERVAL,
}
